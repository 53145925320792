import PageHead from '@components/PageHead';
import User from '@models/User';
import config from 'config';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAPI from '../../hooks/useAPI';
import useSession from '../../hooks/useSession';
import { clearLoginResponseAction } from '../../redux/action';

function LoginPage(props: {
  session?: { authToken: string; userData: User };
  loginResponse?: { login: boolean; returnURL: string };
}) {
  const API = useAPI();
  const router = useRouter();
  const session = useSession();
  const dispatch = useDispatch();
  const { loginResponse } = props;

  const rUrl =
    process.browser && new URLSearchParams(window.location.search).get('url');

  const returnUrl = loginResponse.returnURL;

  // OAuth 2.0 登入完成
  useEffect(() => {
    if (loginResponse.login) {
      session.saveSession(props.session.authToken, props.session.userData);
      dispatch(clearLoginResponseAction());
      if (returnUrl === undefined) {
        alert('登入成功！');
        router.push('/');
        return;
      }
      // @ts-ignore
      window.location.href = returnUrl;
    }
  }, []);

  const googleLogin = async () => {
    const clientId =
      window.location.host === 'www.hoost.tw'
        ? '823223159033-iksqv065c0323c0v5ogts8fmh8lhdlmm.apps.googleusercontent.com'
        : '136689384278-lkbea1b4au5nos02moc9lui5b1uu9k2d.apps.googleusercontent.com';

    window.location.href =
      'https://accounts.google.com/o/oauth2/v2/auth' +
      '?response_type=code' +
      '&client_id=' +
      clientId +
      '&scope=openid%20email%20https://www.googleapis.com/auth/userinfo.profile' +
      '&redirect_uri=https://' +
      window.location.host +
      '/login' +
      '&state=' +
      (rUrl ? rUrl : 'https://' + window.location.host);
  };

  async function newFbLogin() {
    const clientId =
      window.location.host === 'www.hoost.tw'
        ? '2378573225773772'
        : '255431225727566';

    window.location.href =
      'https://www.facebook.com/v9.0/dialog/oauth' +
      '?client_id=' +
      clientId +
      '&redirect_uri=https://' +
      window.location.host +
      '/login' +
      '&state=' +
      (rUrl ? rUrl : 'https://' + window.location.host);
  }

  const appleLogin = async () => {
    try {
      const res: {
        authorization: { code: string; id_token: string };
      } = await window.APPLE_API.auth.signIn();
      await API.login({ id_token: res.authorization.id_token });
      if (returnUrl === undefined) {
        alert('登入成功！');
        router.push('/');
        return;
      }
      // @ts-ignore
      window.location.href = rUrl;
    } catch (err) {
      console.error(err);
      alert('發生錯誤，請聯繫客服人員');
      return;
    }
  };

  return (
    <div>
      <PageHead
        title="登入 | Hoost"
        pageUrl={config.DEFAULT_PAGE_URL + '/login'}
      />
      <div className="login-page">
        <div className="row">
          <div className="left-col">
            <div>
              <img src={'/images/app-preview.png'} alt="login_img.svg" />
            </div>
          </div>
          <div className="right-col">
            <div className="logo">
              <img src={'/icons/logo/hoost_1.svg'} alt="hoost icon" />
              <p>Hoost</p>
            </div>

            <button className="fb-login" type="button" onClick={newFbLogin}>
              <img src={'/icons/facebook-circular-white.png'} alt="fb" />
              <p>使用 Facebook 帳號登入</p>
            </button>

            <button
              className="google-login"
              type="button"
              onClick={googleLogin}
            >
              <img src={'/icons/google-logo.png'} alt="fb" />
              <p>使用 Gmail 帳號登入</p>
            </button>

            <button className="apple-login" type="button" onClick={appleLogin}>
              <img src={'/icons/apple-logo.png'} alt="fb" />
              <p>使用 Apple ID 登入</p>
            </button>

            <a href="https://creator.hoost.tw/start/login-failed">
              <p className="login-help">沒辦法順利登入？</p>
            </a>

            <div className="copyrights">
              <p>TO HOST, TO SHARE, TO CONNECT.</p>
              <p>© 2020 Hoost, Inc.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
