import { FbGetMeResponse, FbOAuthResponse } from '@models/FbOAuthResponse';
import User from '@models/User';
import ErrorPage from '@pages/ErrorPage';
import API from '@services/index';
import axios from 'axios';
import config from 'config';
import { GetServerSideProps } from 'next';
import React from 'react';
import LoginPage from '../src/pages/LoginPage';

export default function Page(props: {
  error?: { title: string; message: string };
  session?: { authToken: string; userData: User };
  loginResponse?: { login: boolean; returnURL: string };
}) {
  if (props.error)
    return (
      <ErrorPage title={props.error.title} description={props.error.message} />
    );
  return <LoginPage {...props} />;
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  try {
    const code = ctx.query.code;
    const scope = ctx.query.scope;

    // 若請求帶有 code，但沒有 scope，代表是 Facebook API 導回來的請求
    if (code !== undefined && scope === undefined) {
      const fbAppId = config.FB_API_KEY;
      const fbAppSecret = config.FB_CLIENT_SECRET;
      const host = ctx.req.headers.host;
      const res = await axios.get(
        'https://graph.facebook.com/v9.0/oauth/access_token' +
          '?client_id=' +
          fbAppId +
          '&redirect_uri=https://' +
          host +
          '/login' +
          '&client_secret=' +
          fbAppSecret +
          '&code=' +
          code
      );
      const parsedRes: FbOAuthResponse = res.data;
      const res2 = await axios.get(
        'https://graph.facebook.com/me' +
          '?access_token=' +
          parsedRes.access_token +
          '' +
          '&fields=name,email'
      );
      const fbUserInfo: FbGetMeResponse = res2.data;
      const hoostLoginRes = await API.userLogin({
        uid: fbUserInfo.id,
        provider: 'facebook.com',
        access_token: parsedRes.access_token,
      });
      const returnUrl = ctx.query.state;

      ctx.res.setHeader('set-cookie', `p-auth=${hoostLoginRes.p_auth}`);

      return {
        props: {
          session: {
            authToken: hoostLoginRes.auth_token,
            userData: hoostLoginRes.user,
          },
          loginResponse: {
            login: true,
            returnURL:
              returnUrl !== null && returnUrl !== 'null' ? returnUrl : '',
          },
        },
      };
    }

    // 若請求帶有 code，也有 scope，代表是 Google OAuth API 導回來的請求
    if (code !== undefined && scope !== undefined) {
      const googleAppId = config.GOOGLE_API_KEY;
      const googleAppSecret = config.GOOGLE_CLIENT_SECRET;
      const host = ctx.req.headers.host;
      const res = await axios.post(
        'https://oauth2.googleapis.com/token',
        'client_id=' +
          googleAppId +
          '&client_secret=' +
          googleAppSecret +
          '&redirect_uri=https://' +
          host +
          '/login' +
          '&grant_type=authorization_code' +
          '&code=' +
          code
      );

      const getUserInfoRes = await axios.get(
        'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
        { headers: { Authorization: 'Bearer ' + res.data.access_token } }
      );

      const hoostLoginRes = await API.userLogin({
        uid: getUserInfoRes.data.id,
        provider: 'google.com',
        access_token: res.data.id_token,
      });
      const returnUrl = ctx.query.state;

      ctx.res.setHeader('set-cookie', `p-auth=${hoostLoginRes.p_auth}`);

      return {
        props: {
          session: {
            authToken: hoostLoginRes.auth_token,
            userData: hoostLoginRes.user,
          },
          loginResponse: {
            login: true,
            returnURL:
              returnUrl !== null && returnUrl !== 'null' ? returnUrl : '',
          },
        },
      };
    }

    return { props: { loginResponse: { login: false, returnURL: '' } } };
  } catch (err) {
    console.error(err);
    return {
      props: {
        error: {
          title: '登入時發生錯誤',
          message:
            process.env.ENV === 'development'
              ? err.toString()
              : '若問題持續發生，請聯繫客服人員處理',
        },
      },
    };
  }
};
