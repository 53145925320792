import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import { Col, Row } from 'react-grid-system';

export default function ErrorPage(props: {
  title: string;
  description: string;
}) {
  return (
    <div className="not-found-page">
      <Head>
        <title>{props.title + ' | Hoost'}</title>
        <meta property="og:title" content={props.title + ' | Hoost'} />
        <meta name="description" content={props.description} />
        <meta property="og:description" content={props.description} />
      </Head>

      <Row style={{ width: '100%', padding: 0, margin: 0 }}>
        <Col xs={12} style={{ padding: 0, margin: 0 }}>
          <div
            className="banner-bg"
            style={{
              backgroundImage: 'url(/images/undraw_page_not_found_su7k.svg)',
            }}
          >
            <div className="banner-text">
              <h1>{props.title}</h1>
              <h5>{props.description}</h5>
              <Link href="/">
                <button>回首頁</button>
              </Link>
            </div>
            <div className="bg-overlay" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
